<header
  *ngIf="SessionValue"
  class="topmenu navbar-expand-xs navbar-expand-lg container-fluid"
>


  <!-- Sidebar Toggle -->
  <button
    (click)="onToolbarMenuToggle()"
    type="button"
    class="sidebar-toggle navbar-toggle pull-left"> 
    <span class="sidebar-arrow"></span>
  </button>

  <!-- brand -->
  <a href="/dashboard">
    <img class='logoImage3' id='companyLogo' src='assets/salvus_logo.png' alt='Salvus' />
  </a>
    
  <ul
    class="navbar-nav top-right-menu justify-content-end float-right ml-auto"
    style="flex-direction: row !important"
  >
    <li class="nav-item dropdown">
      <a
        class="nav-link pt-3 ml-2"
        href="#User/add#"
        id="dropdownMenuLink"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        style="
          padding-right: 0.75rem !important;
          padding-left: 0.75rem !important;
        "
      >
        <i class="lnr icon-profile"></i>
      </a>
      <ul
        class="dropdown-menu"
        aria-labelledby="dropdownMenuLink"
        x-placement="bottom-start"
        style="
          position: absolute;
          will-change: transform;
          top: 0px;
          left: 0px;
          transform: translate3d(0px, 55px, 0px);
        "
      >
        <li>
          <a class="dropdown-item">{{ currentUser }}</a>
        </li>
        <div class="dropdown-divider"></div>
        <li>
          <a class="dropdown-item">Role : {{ role }}</a>
        </li>
        <div class="dropdown-divider"></div>
        <li><a class="dropdown-item" (click)="LogOut()">Log Out</a></li>
      </ul>
    </li>
  </ul>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <p class="pt-3">Welcome, <span class="fw-bold">{{ name }}</span></p>
      </li>
    </ul>
  </div>
</header>

<div id="wrapper-profile" *ngIf="SessionValue">
  <!-- Sidebar Start-->
  <aside
    class="left-panel"
    tabindex="5000"
    style="outline: none"
    id="side-bar"
    [ngStyle]="{
      'width.px': contentWidth,
      overflow: contentOverflow,
      left: leftMobile
    }"
    *ngIf="navInMobile"
    (click)="navChange()"
  >
    <!-- Navbar Start -->
    <nav class="navigation">
      <ul class="list-unstyled components">
        <li *ngIf="isAuthenticated('dashboard', 'dashboard')" routerLinkActive="active-indicator">
          <a routerLink="/dashboard">
            <i class="lnr icon-home"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Dashboard
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Dashboard
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="!isTraining && isAuthenticated('audit-trail', 'audit-trail')" routerLinkActive="active-indicator">
          <a routerLink="/case-import">
            <i class="lnr icon-arrow-down"></i>
            <span *ngIf="contentVisiablity == 'unset'" class="nav-label">
              Case Import
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Case Import
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('users', 'users')" routerLinkActive="active-indicator">
          <a routerLink="/users">
            <i class="lnr icon-users2"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Users
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Users
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('roles', 'roles')" routerLinkActive="active-indicator">
          <a routerLink="/roles">
            <i class="lnr icon-users"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Roles
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Roles
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('workflow', 'workflow')" routerLinkActive="active-indicator">
          <a routerLink="/workflow">
            <i class="lnr icon-files"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Workflow
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Workflow
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('case', 'worklist')" routerLinkActive="active-indicator">
          <a routerLink="/case" style="display: none;"></a>
          <a routerLink="/case/worklist">
            <i class="lnr icon-list4"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Case Worklist
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Case Worklist
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('company-unit', 'company-unit')" routerLinkActive="active-indicator">
          <a routerLink="/company-unit">
            <i class="lnr icon-widgets"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Company Unit
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Company Unit
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('narratives', 'narratives')" routerLinkActive="active-indicator">
          <a routerLink="/narratives">
            <i class="lnr icon-book2"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Narratives
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Narratives
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('distribution-contacts', 'distribution-contacts')" routerLinkActive="active-indicator">
          <a routerLink="/distribution-contacts">
            <i class="lnr icon-contacts"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Submission Contacts
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Submission Contacts
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('product-dictionary', 'product-dictionary')" routerLinkActive="active-indicator">
          <a routerLink="/product-dictionary">
            <i class="lnr icon-folder-user"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Product Dictionary
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Product Dictionary
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('meddra-dictionary', 'meddra-dictionary')" routerLinkActive="active-indicator">
          <a routerLink="/meddra-dictionary/load-meddra">
            <i class="lnr icon-folder-upload"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Load Dictionary
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Load Dictionary
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('study-protocol', 'study-protocol')" routerLinkActive="active-indicator">
          <a routerLink="/study-protocol">
            <i class="lnr icon-folder-bookmark2"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Study Protocol
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Study Protocol
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('duplicate-search', 'duplicate-search')" routerLinkActive="active-indicator">
          <a routerLink="/duplicate-search/duplicate-search">
            <i class="lnr icon-control_point_duplicate"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Duplicate Search Configuration
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Duplicate Search Configuration
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('report', 'report')" routerLinkActive="active-indicator">
          <a routerLink="/reports">
            <i class="lnr icon-chart-bars"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Reports
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Reports
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('line-report', 'line-report')" routerLinkActive="active-indicator">
          <a routerLink="/reports/line-list">
            <i class="lnr icon-domain"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Linelisting Reports
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Linelisting Reports
              </span>
            </div>
          </a>
        </li>

        <li *ngIf="isAuthenticated('audit-trail', 'audit-trail')" routerLinkActive="active-indicator">
          <a routerLink="/audit-trial">
            <i class="lnr icon-clipboard-down"></i>
            <span
              *ngIf="contentVisiablity == 'unset'"
              class="nav-label">
              Audit Trial
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Audit Trial
              </span>
            </div>
          </a>
        </li>

        <!-- <li *ngIf="isAuthenticated('audit-trail', 'audit-trail')" routerLinkActive="active-indicator">
          <a routerLink="/email-config">
            <i class="lnr icon-envelope"></i>
            <span *ngIf="contentVisiablity == 'unset'" class="nav-label">
              Email Configuration
            </span>
            <div class="collapse-div">
              <span *ngIf="contentVisiablity == 'collapse'" class="collapse-label">
                Email Configuration
              </span>
            </div>
          </a>
        </li> -->
        
      </ul>
    </nav>
  </aside>
  <div class="page-wrapper pb-0">
    <div class="page-content mb-3">
      <div
        class="pagebar container-fluid col-md-12"
        [ngStyle]="{ 'margin-left.px': contentMarginLeft }"
      >
      </div>
      <div class="card" [ngStyle]="{ 'margin-left.px': main_content }">
        <div class="card-body">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <div
      class="footer container-fluid col-md-12"
      [ngStyle]="{ 'margin-left.px': main_content }"
    >
      <p><i class="lnr icon-copyright"></i> DATACREDS 2024. Version 1.0</p>
    </div>
  </div>
</div>
<!-- <script type="text/javascript">
  function googleTranslateElementInit() {
    new google.translate.TranslateElement(
      { pageLanguage: 'en' },
      'google_translate_element'
    );
  } 
</script>
<script type="text/javascript"
  src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script> -->
